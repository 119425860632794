<template>
    <div class="ban-save" id="ban-save">
        <div class="service">
            <div class="main">
                <div class="body">
                    <div class="container" style="padding-top:40px;margin-bottom: 30px;width: 80%">
                    <el-card>
                        <span style="text-align: center;display:block;margin-bottom:10px;font-size: 22px;font-weight: 700;">中国资产交易网客户满意度调查问卷</span>
                        <span> 尊敬的客户：</span>
                        <br><span style=" text-indent:2em;display: inline-block;">
                          您好！
                        非常感谢您信任并选择了中国资产交易网！为了不断提高网站服务功能与服务质量，本公司特进行此项客户满意度调查。
                        期盼您在百忙之中给予我们客观的评价，并提出宝贵意见和建议，您的评价和建议是我们前进的动力，
                        我们将虚心听取并及时改进，为您提供更好的服务。
                        感谢您的配合与支持！</span>
                        
                        <span style="text-align: left;display:block;margin-top:20px; margin-bottom:10px;font-size: 16px;font-weight: 700;">
                            一、网站服务质量
                        </span>

                        <el-form :model="dataForm" status-icon :rules="rules"  ref="dataForm" label-position="top" label-width="180px" class="demo-ruleForm">
                            <el-form-item label="1.服务态度" prop="taid1">
                                <el-radio-group v-model="dataForm.taid1" size="medium">
                                <el-radio v-for="(item, index) in taid1Options" :key="index" :label="item.value"
                                    :disabled="item.disabled">{{item.label}}</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="您希望我们提升的: " prop="taid2">
                                <el-input v-model="dataForm.taid2" type="textarea" placeholder="请输入" :maxlength="140" show-word-limit
                                :autosize="{minRows: 2, maxRows: 4}" :style="{width: '100%'}"></el-input>
                            </el-form-item>
                            <el-form-item label="2.服务效率" prop="xiaol1">
                                <el-radio-group v-model="dataForm.xiaol1" size="medium">
                                <el-radio v-for="(item, index) in xiaol1Options" :key="index" :label="item.value"
                                    :disabled="item.disabled">{{item.label}}</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="您希望我们提升的: " prop="xiaol2">
                                <el-input v-model="dataForm.xiaol2" type="textarea" placeholder="请输入" :maxlength="140" show-word-limit
                                :autosize="{minRows: 2, maxRows: 4}" :style="{width: '100%'}"></el-input>
                            </el-form-item>
                            <el-form-item label="3.服务效果" prop="xiaog1">
                                <el-radio-group v-model="dataForm.xiaog1" size="medium">
                                <el-radio v-for="(item, index) in xiaog1Options" :key="index" :label="item.value"
                                    :disabled="item.disabled">{{item.label}}</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="您希望我们提升的: " prop="xiaog2">
                                <el-input v-model="dataForm.xiaog2" type="textarea" placeholder="请输入......" :maxlength="140"
                                show-word-limit :autosize="{minRows: 2, maxRows: 4}" :style="{width: '100%'}"></el-input>
                            </el-form-item>
                            <span style="text-align: left;display:block;margin-bottom:10px;font-size: 16px;font-weight: 700;">
                                二、网站功能及内容
                        </span>
                            <el-form-item label="1.网站的稳定性与兼容性" prop="jianr1">
                                <el-radio-group v-model="dataForm.jianr1" size="medium">
                                <el-radio v-for="(item, index) in jianr1Options" :key="index" :label="item.value"
                                    :disabled="item.disabled">{{item.label}}</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label-width="260px" label="您的建议（或您在操作中遇到的问题）:" prop="jianr2">
                                <el-input v-model="dataForm.jianr2" type="textarea" placeholder="请输入......" :maxlength="140"
                                show-word-limit :autosize="{minRows: 2, maxRows: 4}" :style="{width: '100%'}"></el-input>
                            </el-form-item>
                            <el-form-item label="2.网站页面内容排版（是否便于您使用并满足您的业务需求）:" prop="paib1">
                                <el-radio-group v-model="dataForm.paib1" size="medium">
                                <el-radio v-for="(item, index) in paib1Options" :key="index" :label="item.value"
                                    :disabled="item.disabled">{{item.label}}</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label-width="260px" label="您的建议（或您在操作中遇到的不便）：" prop="paib2">
                                <el-input v-model="dataForm.paib2" type="textarea" placeholder="请输入......" :maxlength="140"
                                show-word-limit :autosize="{minRows: 2, maxRows: 4}" :style="{width: '100%'}"></el-input>
                            </el-form-item>
                            <el-form-item label="3.对中国资产交易网相对其他同业网站的满意度" prop="manyd1">
                                <el-radio-group v-model="dataForm.manyd1" size="medium">
                                <el-radio v-for="(item, index) in manyd1Options" :key="index" :label="item.value"
                                    :disabled="item.disabled">{{item.label}}</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label-width="260px" label="您的建议（或您希望我们改进的方向）：" prop="manyd2">
                                <el-input v-model="dataForm.manyd2" type="textarea" placeholder="请输入......" :maxlength="140"
                                show-word-limit :autosize="{minRows: 2, maxRows: 4}" :style="{width: '100%'}"></el-input>
                            </el-form-item>
                            <span style="text-align: left;display:block;margin-bottom:10px;font-size: 16px;font-weight: 700;">
                                三、开放问题
                        </span>
                            <el-form-item label="1.您是以何种途径了解到中国资产交易网：" prop="tuj1temp">
                                <el-checkbox-group v-model="dataForm.tuj1temp" size="medium">
                                <el-checkbox v-for="(item, index) in tuj1Options" :key="index" :label="item.value"
                                    :disabled="item.disabled">{{item.label}}</el-checkbox>
                                </el-checkbox-group>
                            </el-form-item>
                            <el-form-item label-width="260px" label="其他：" prop="tuj2">
                                <el-input v-model="dataForm.tuj2" type="textarea" placeholder="请输入......" :maxlength="140"
                                show-word-limit :autosize="{minRows: 2, maxRows: 4}" :style="{width: '100%'}"></el-input>
                            </el-form-item>
                            <el-form-item label="2.您关注的项目类别" prop="xiangmul1temp">
                                <el-checkbox-group v-model="dataForm.xiangmul1temp" size="medium">
                                <el-checkbox v-for="(item, index) in xiangmul1Options" :key="index" :label="item.value"
                                    :disabled="item.disabled">{{item.label}}</el-checkbox>
                                </el-checkbox-group>
                            </el-form-item>
                            <el-form-item label-width="260px" label="其他：" prop="xiangmul101">
                                <el-input v-model="dataForm.xiangmul101" type="textarea" placeholder="请输入......" :maxlength="140"
                                show-word-limit :autosize="{minRows: 2, maxRows: 4}" :style="{width: '100%'}"></el-input>
                            </el-form-item>
                            <el-form-item label-width="260px" label="您期望我们推介的或增设的项目类别：" prop="xiangmul2">
                                <el-input v-model="dataForm.xiangmul2" type="textarea" placeholder="请输入......" :maxlength="140"
                                show-word-limit :autosize="{minRows: 2, maxRows: 4}" :style="{width: '100%'}"></el-input>
                            </el-form-item>
                            <el-form-item label="3.将中国资产交易网项目向其他客户推荐的可能：" prop="tuij1temp">
                                <el-checkbox-group v-model="dataForm.tuij1temp" size="medium">
                                <el-checkbox v-for="(item, index) in tuij1Options" :key="index" :label="item.value"
                                    :disabled="item.disabled">{{item.label}}</el-checkbox>
                                </el-checkbox-group>
                            </el-form-item>
                            


                            <el-form-item>
                                <el-button type="primary" :disabled="disabledbtn" @click="submitForm()" v-loading.fullscreen.lock="fullscreenLoading">提交</el-button>
                                <el-button @click="resetForm()">重置</el-button>
                            </el-form-item>
                        </el-form>
                    </el-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    export default {
        data() {
            return {
                loading: true,
                dataForm: {
                taid1: '',
                taid2: '',
                xiaol1: '',
                xiaol2: '',
                xiaog1: '',
                xiaog2: '',
                jianr1: '',
                jianr2: '',
                paib1: '',
                paib2: '',
                manyd1: '',
                manyd2: '',
                tuj1: '',
                tuj1temp: [],
                tuj2: '',
                xiangmul1: '',
                xiangmul1temp: [],
                xiangmul101: '',
                xiangmul2: '',
                tuij1: '',
                tuij1temp: [],
            },
            rules: {
                taid1: [{
                required: true,
                message: '1.服务态度不能为空',
                trigger: 'change'
                }],
                taid2: [],
                xiaol1: [{
                required: true,
                message: '2.服务效率不能为空',
                trigger: 'change'
                }],
                xiaol2: [],
                xiaog1: [{
                required: true,
                message: '3.服务效果不能为空',
                trigger: 'change'
                }],
                xiaog2: [],
                jianr1: [{
                required: true,
                message: '1.网站的稳定性与兼容性不能为空',
                trigger: 'change'
                }],
                jianr2: [],
                paib1: [{
                required: true,
                message: '2.网站页面内容排版（是否便于您使用并满足您的业务需求）不能为空',
                trigger: 'change'
                }],
                paib2: [],
                manyd1: [{
                required: true,
                message: '3.对中国资产交易网相对其他同业网站的满意度不能为空',
                trigger: 'change'
                }],
                manyd2: [],
                tuj1temp: [{
                required: true,
                type: 'array',
                message: '请至少选择一个途径',
                trigger: 'change'
                }],
                tuj2: [],
                xiangmul1temp: [{
                required: true,
                type: 'array',
                message: '请至少选择一个项目类别',
                trigger: 'change'
                }],
                xiangmul101: [],
                xiangmul2: [],
                tuij1temp: [{
                required: true,
                type: 'array',
                message: '请至少选择一个推荐的可能',
                trigger: 'change'
                }],
            },
            taid1Options: [{
                "label": "非常满意",
                "value": "非常满意"
            }, {
                "label": "满意",
                "value": "满意"
            }, {
                "label": "一般",
                "value": "一般"
            }],
            xiaol1Options: [{
                "label": "非常满意",
                "value": "非常满意"
            }, {
                "label": "满意",
                "value": "满意"
            }, {
                "label": "一般",
                "value": "一般"
            }],
            xiaog1Options: [{
                "label": "非常满意",
                "value": "非常满意"
            }, {
                "label": "满意",
                "value": "满意"
            }, {
                "label": "一般",
                "value": "一般"
            }],
            jianr1Options: [{
                "label": "非常满意",
                "value": "非常满意"
            }, {
                "label": "满意",
                "value": "满意"
            }, {
                "label": "一般",
                "value": "一般"
            }, {
                "label": "不满意",
                "value": "不满意"
            }],
            paib1Options: [{
                "label": "非常满意",
                "value": "非常满意"
            }, {
                "label": "满意",
                "value": "满意"
            }, {
                "label": "一般",
                "value": "一般"
            }, {
                "label": "不满意",
                "value": "不满意"
            }],
            manyd1Options: [{
                "label": "非常满意",
                "value": "非常满意"
            }, {
                "label": "满意",
                "value": "满意"
            }, {
                "label": "一般",
                "value": "一般"
            }, {
                "label": "不满意",
                "value": "不满意"
            }],
            tuj1Options: [{
                "label": "朋友介绍",
                "value": "朋友介绍"
            }, {
                "label": "其他同业网站推荐",
                "value": "其他同业网站推荐"
            }],
            xiangmul1Options: [{
                "label": "废铁",
                "value": "废铁"
            }, {
                "label": "废铜",
                "value": "废铜"
            }, {
                "label": "废钢",
                "value": "废钢"
            }, {
                "label": "废橡胶",
                "value": "废橡胶"
            }, {
                "label": "闲废设备",
                "value": "闲废设备"
            }, {
                "label": "闲废车辆",
                "value": "闲废车辆"
            }, {
                "label": "工业固废",
                "value": "工业固废"
            }],
            tuij1Options: [{
                "label": "很大可能",
                "value": "很大可能"
            }, {
                "label": "一些可能",
                "value": "一些可能"
            }, {
                "label": "不可能",
                "value": "不可能"
            }],
            disabledbtn: false
            }
        },
        computed: {
          
        },
        mounted() {
            if(this.$route.query.id)
                this.biddingFlag = this.$route.query.id;

        },
        methods: {
            ...mapGetters(["getUser", "getToken"]),
            submitForm() {
                const _this = this
                _this.disabledbtn = true
                this.$refs['dataForm'].validate((valid) => {
                    if (!valid) {
                        _this.disabledbtn = false
                        return false
                    }
                    const  list1tuij1 = this.dataForm.tuij1temp
                    const  list1tuj1 = this.dataForm.tuj1temp
                    const  list1xiangmul1 = this.dataForm.xiangmul1temp
                    list1xiangmul1.push(_this.dataForm.xiangmul101)
                    const listString1 = list1tuij1.join(',')
                    const listString2 = list1tuj1.join(',')
                    const listString3 = list1xiangmul1.join(',')
                    this.dataForm.tuij1= listString1.toString()
                    this.dataForm.xiangmul1= listString3.toString()
                    this.dataForm.tuj1= listString2.toString()
                    _this.dataForm.xiangmul1 = listString3
                    // console.log('listString')
                    // console.log(listString1)
                    // console.log(listString2)
                    // console.log(listString3)
                    // console.log(_this.dataForm.xiangmul101)
                    _this.$post('/shoppingui/ecquest/xiaosave', this.dataForm).then((data) => {
                        console.log('data')
                        console.log(data)
                        if (data.code == 500) {
                            this.$message({
                                message: data.msg,
                                offset: 130,
                                type: "error",
                                showClose: "true",
                                onClose:()=>{
                                _this.disabledbtn = false
                            }
                            });
                            return false;
                        }else if (data.code == 0) {
                            this.$message({
                            message: '提交成功！感谢您的参与！',
                            offset: 130,
                            type: "success",
                            showClose: "true",
                            onClose:()=>{
                                this.resetForm();
                                _this.disabledbtn = false
                            }
                        });
                  
                        }else{
                            this.$message({
                            message: data.msg,
                            offset: 130,
                            type: "error",
                            showClose: "true",
                            onClose:()=>{
                                _this.disabledbtn = false
                            }
                        });
                        
                        return false;
                        }
                        
                    }).catch(() => {})
                });
            },
            resetForm() {
                this.$refs['dataForm'].resetFields();
            }
        },
    }
</script>

<style scoped lang="scss">
    @import "../assets/css/index.css";
    @import "../assets/css/page/service.scss";
    .gotoDetails{
        cursor: pointer;
    }
</style>
